import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipUkIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="uk"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-NM7D8VH"
    ></IndexPage>
  );
};

export default CitizenshipUkIndexPage;
